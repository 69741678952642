/**
 * @generated SignedSource<<79e45e11cd2afb8f536da75f77a05c99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CloudProvider = "AWS" | "Azure" | "GCP" | "Kubernetes" | "TencentCloud" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AccountDetailsMeta_account$data = {
  readonly credentialInfo: {
    readonly clientID?: string;
    readonly keyID?: string;
    readonly tenantID?: string;
  } | null | undefined;
  readonly discovery: {
    readonly __typename: "AccountDiscovery";
  } | null | undefined;
  readonly email: string | null | undefined;
  readonly key: string;
  readonly name: string;
  readonly path: string | null | undefined;
  readonly provider: CloudProvider;
  readonly securityContext: string;
  readonly tags: ReadonlyArray<{
    readonly key: string;
    readonly value: string | null | undefined;
  }> | null | undefined;
  readonly validated_at: string | null | undefined;
  readonly " $fragmentType": "AccountDetailsMeta_account";
};
export type AccountDetailsMeta_account$key = {
  readonly " $data"?: AccountDetailsMeta_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountDetailsMeta_account">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountDetailsMeta_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountDiscovery",
      "kind": "LinkedField",
      "name": "discovery",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Tag",
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "provider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "securityContext",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "validated_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "credentialInfo",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tenantID",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clientID",
              "storageKey": null
            }
          ],
          "type": "AzureCredentialInfo",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "keyID",
              "storageKey": null
            }
          ],
          "type": "TencentCloudCredentialInfo",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};
})();

(node as any).hash = "782aa0dcff1874a02d7aeb2cf86ac372";

export default node;
