import type { PropsWithChildren } from "react";

import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { AppHeader } from "@stacklet/ui";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Link } from "app/components";
import { ToggleLinkGroup } from "app/components/ToggleLinkGroup";
import { useConfig } from "app/contexts";

import type { BreadCrumbsHeader_query$key } from "./__generated__/BreadCrumbsHeader_query.graphql";

export interface BreadCrumbElement {
  label?: string;
  target?: string;
  tabs?: ({ label: string; target: string } | undefined)[];
}

interface Props extends PropsWithChildren {
  queryRef: BreadCrumbsHeader_query$key;
  breadcrumbs: BreadCrumbElement[];
}

export function BreadCrumbsHeader({ queryRef, breadcrumbs }: Props) {
  const config = useConfig();
  const supportEnabled = config ? !config?.is_poc : false;

  const data = useFragment(
    graphql`
      fragment BreadCrumbsHeader_query on Query {
        whoami {
          email
          displayName
        }
        UrlConfig {
          docs
        }
      }
    `,
    queryRef,
  );

  const { displayName, email } = data.whoami;
  const docs = data.UrlConfig?.docs;

  return (
    <Box marginBottom={9}>
      <AppHeader
        docsUrl={docs}
        email={email}
        supportEnabled={supportEnabled}
        username={displayName}
      >
        <Box sx={{ display: "flex" }}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ color: "white" }}>
            {breadcrumbs.map((breadCrumb, index) => {
              if (Array.isArray(breadCrumb.tabs)) {
                return <ToggleLinkGroup key={index} tabs={breadCrumb.tabs} />;
              } else {
                return breadCrumb.target ? (
                  <Link key={index} to={breadCrumb.target}>
                    <Box>{breadCrumb.label}</Box>
                  </Link>
                ) : (
                  <Typography key={index}>{breadCrumb.label}</Typography>
                );
              }
            })}
          </Breadcrumbs>
        </Box>
      </AppHeader>
    </Box>
  );
}
