import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import WarningIcon from "@mui/icons-material/WarningAmberRounded";
import { Tooltip } from "@stacklet/ui";
import { COLORS } from "@stacklet/ui";

interface Props {
  status: "success" | "warning" | "error";
  message?: string;
  inline?: boolean;
}

export function StatusIcon({ status, message, inline = false }: Props) {
  return (
    <>
      {status === "error" ? (
        <Tooltip title={message || ""}>
          <span>
            <ErrorIcon
              aria-label="error"
              data-testid="error-icon"
              fontSize={inline ? "inherit" : "medium"}
              sx={{ mr: inline ? 0.5 : undefined, fill: COLORS.red.D20 }}
            />
          </span>
        </Tooltip>
      ) : null}
      {status === "warning" ? (
        <Tooltip title={message || ""}>
          <span>
            <WarningIcon
              aria-label="warning"
              data-testid="warning-icon"
              fontSize={inline ? "inherit" : "medium"}
              sx={{ mr: inline ? 0.5 : undefined, fill: COLORS.orange.D20 }}
            />
          </span>
        </Tooltip>
      ) : null}

      {status === "success" ? (
        <CheckCircleIcon aria-label="success" sx={{ fill: "green" }} />
      ) : null}
    </>
  );
}
