import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { COLORS } from "@stacklet/ui";

import { Link } from "app/components";

const TabGroupWrapper = styled("div")({
  width: "100%",
  overflow: "hidden",
  "& a:first-of-type div": {
    borderRadius: "2px 0 0 2px",
  },
  "& a:last-child div": {
    borderRadius: "0 2px 2px 0",
  },
  "& a:only-child div": {
    borderRadius: "2px 2px 2px 2px",
  },
});

interface Props {
  tabs: ({ label: string; target: string } | undefined)[];
  type?: "viewTabs" | "breadcrumbs";
}

export function ToggleLinkGroup({ tabs, type = "breadcrumbs" }: Props) {
  const theme = useTheme();

  return (
    <TabGroupWrapper>
      <Box
        display="flex"
        sx={{
          flexWrap: "wrap",
          minWidth: 0,
          "& > a": {
            flexShrink: 0,
            marginTop: "2px",
            marginBottom: "2px",
            "& > div": {
              marginLeft: "-1px",
            },
          },
          "& > a:first-of-type > div": {
            marginLeft: 0,
          },
        }}
      >
        {tabs.map(
          (tab, index) =>
            tab?.label &&
            tab?.target && (
              <Link
                activeBgColor={
                  type === "breadcrumbs"
                    ? theme.palette.common.white
                    : COLORS.navy.L10
                }
                activeTextColor={
                  type === "breadcrumbs"
                    ? COLORS.navy.L10
                    : theme.palette.common.white
                }
                key={index}
                textDecoration="none"
                to={tab.target}
              >
                <Box
                  sx={{
                    border: `1px solid ${
                      type === "breadcrumbs"
                        ? theme.palette.common.white
                        : COLORS.navy.L10
                    }`,
                    py: 0.5,
                    px: 1,
                    height: "26px",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  {tab.label}
                </Box>
              </Link>
            ),
        )}
      </Box>
    </TabGroupWrapper>
  );
}
