import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { StatusIcon } from "app/components/icons/StatusIcon";

interface Props {
  hasDiscovery: boolean;
}

export function NoSecurityContext({ hasDiscovery }: Props) {
  if (hasDiscovery) {
    return "--";
  }

  return (
    <Tooltip title="Stacklet cannot operate in this account because its security context is missing.">
      <Typography sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        --
        <StatusIcon status="warning" inline />
      </Typography>
    </Tooltip>
  );
}
